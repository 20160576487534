import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Dasa, Geneone, Genomic, MenuIcon } from '../../assets/Images';
import { getAgendaState } from '../../store/slices/agenda';
import { getVideoState } from '../../store/slices/video';
import { Button, ButtonDisabled } from '../button/Button';
import MenuButton from './MenuButton';

export default function Navbar() {
	const { t, i18n } = useTranslation();
	const [showMenu, setShowMenu] = useState(false);
	const video = useSelector(getVideoState);
	const history = useHistory();
	const {pathname} = useLocation();
	const exibirAoVivo = !pathname.split('/').includes("chat")
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	}

	return (
		<>
			<ContainerGeral>
				<ImagemLogo>
					<Genomic />
				</ImagemLogo>
				<ContainerDireita>
					<Linha1>
						<ImagemDasa>
							<Dasa />
						</ImagemDasa>
						<ImagemGeneOne >
							<Geneone />
						</ImagemGeneOne>
					</Linha1>
					<Linha2>
						<Menu>
							<SobreEvento onClick={() => {history.push('/')}}>{t('navbar.sobre')}</SobreEvento>
							<Speakers onClick={() => {history.push('/#speaker')}}> {t('navbar.speaker')}</Speakers>
							<Agenda onClick={() => { history.push('/#agenda') }}>{t('navbar.agenda')}</Agenda>
							<Contato onClick={() => { history.push('/#contato')}}>{t('navbar.contato')}</Contato>
						</Menu>
						{exibirAoVivo && video.ao_vivo === true && <div style={{marginLeft:'auto'}}>
							<Button  onClick={() => {history.push(`/chat`)}}>{t('navbar.assista')}</Button>
						</div> }
					</Linha2>
				</ContainerDireita>
			</ContainerGeral>
			<BarraMenuResponsivo>
				<MenuButton onClick={() => toggleMenu()} />
				<AssistaAgora>
					{exibirAoVivo && video.ao_vivo === true && <Button onClick={() => {history.push(`/chat`)}}>{t('navbar.assista')}</Button> }
				</AssistaAgora>
			</BarraMenuResponsivo>
			<MenuResponsivo style={showMenu ? {left: 0}:{left: '-100vw'}}>
				<SobreEvento onClick={() => {history.push('/');toggleMenu();}}>{t('navbar.sobre')}</SobreEvento>
				<Speakers onClick={() => {history.push('/#speaker');toggleMenu();}}> {t('navbar.speaker')}</Speakers>
				<Agenda onClick={() => { history.push('/#agenda') ;toggleMenu();}}>{t('navbar.agenda')}</Agenda>
				<Contato onClick={() => { history.push('/#contato');toggleMenu();}}>{t('navbar.contato')}</Contato>
			</MenuResponsivo>
		</>
	)
}

const ContainerGeral = styled.div`
	padding-right:10%;
	padding-left:10%;
	width:100%;
	height:200px;
	background-repeat:no-repeat;
	background-color: ${props => props.theme.darkBlue + "AA"};
	background-image:url('./assets/topo_03.png');
	background-size:contain;
	color:white;
	position:sticky;
	top:0;
	backdrop-filter: blur(10px);
	display:flex;
	flex-direction:row;
	z-index:99;

	@media (max-width: 950px) {
		padding: 0;
		width: 100%;
		height:unset;
		background-image: unset;
	}
`

const BarraMenuResponsivo = styled.div`
	display: none;

	@media (max-width: 950px) {
		display: flex;
		position: fixed;
		width: 100%;
		height: 50px;
		background-color:${props => props.theme.orange};
    align-items: center;
		z-index: 99;
	}
`;

const IconeMenuResposivo = styled.div`
	
`;

const AssistaAgora = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: end;
	align-content: center;
	justify-content: flex-end;
`;

const MenuResponsivo = styled.div`
	width: 90vw;
	height: 100vh;
	background-color: ${props => props.theme.darkBlue + "E3"};
	backdrop-filter: blur(10px);
	position: fixed;
	left: 0;
	z-index: 300;
	padding-top: 10px;
	margin-top: 50px;

	transition: left .5s;

	flex-direction: column;
	list-style-type: none;
	justify-content: space-evenly;
	align-items: flex-start;

	& li{
		cursor: pointer;
		text-transform: uppercase;
		color: #ffffff;
		padding: 15px;

		&:hover{
			background-color: #FFFFFF10;
		}

		&.selected{
			font-weight: bold;
		}
	}
`;


const ContainerGeral2 = styled.div`
	display:none;
	@media (max-width: 950px) {
		display:flex;
		height:200px;
		background-color:blue;
	}
`
const ContainerDireita = styled.div`
	display:flex;
	flex-direction:column;
	flex-wrap: wrap;
	width:100%;

	@media (max-width: 950px) {
    align-items: center;
		width:50%;
	}
	
`
const Linha1 = styled.div`
	margin-top:30px;
	padding:10px;
	width:100%;
	display:flex;
	flex-direction:row;
	@media (max-width: 950px) {
		margin: 0;
		padding: 10px;
    align-items: center;
    height: 100%;
	}

`
const Linha2 = styled.div`
	padding:10px;
	display:flex;
	flex-direction:row;
	min-width:100%;
	align-content:space-between;
	min-width:600px;
	@media (max-width: 950px) {
		display:none;
	}
`

const Menu = styled.ul`
	max-width:630px;
	width:100%;
	display:flex;
	flex-direction:row;
	list-style-type: none;
	justify-content: space-evenly;
	align-items: flex-end;
	& li{
		cursor: pointer;
		text-transform: uppercase;
		&.selected{
			font-weight: bold;
		}
	}
`
const SobreEvento = styled.li`

`
const Speakers = styled.li`

`
const Agenda = styled.li`

`
const Contato = styled.li`

`

const ImagemLogo = styled.div`
	margin-top:30px;

	@media (max-width: 950px) {
		margin: 10px;
		width: 50%;
		
		& img{
			width: 40%;
			height: auto;
		}
	}
`
const ImagemDasa = styled.div`
	margin-left: auto;
	padding-top:14px;
	& svg{
		height:26px;
		width:auto;
	}
	
	@media (max-width: 950px) {
		width: 35%;
		padding-top:unset;
		& svg{
			height:auto;
			width: 100%;
			max-width: 110px;
		}
	}
`
const ImagemGeneOne = styled.div`
	padding-left:40px;
	& svg{
		height:65px;
		width:auto;
	}
	
	@media (max-width: 950px) {
		padding-left: 10px;
		width: 65%;
		& svg{
			height:auto;
			width: 100%;
			max-width: 200px;
		}
	}
`
