import React from 'react'
import styled from "styled-components";
import { MenuIcon } from '../../assets/Images';
import { Button } from '../button/Button';

export default function MenuButton({onClick}:any) {
	return (
		<BarsButton onClick={()=>onClick()}>
			<MenuIcon />
		</BarsButton>
	)
}

const BarsButton = styled.div`
	cursor: pointer;
	border: 2px solid ${props => props.theme.orange};
	background-color:${props => props.theme.orange};
	height:40px;
	width:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	& svg{
		width: 20px;
		height: auto;
	}
	
	&:hover{
		border: 2px solid ${props => props.theme.orange};
		color:${props => props.theme.orange};
		background-color: #ffffff33;
	}

`