import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'DasaSans', 'Roboto', 'sans-serif';
    -webkit-tap-highlight-color: transparent;
  }
  *:focus{
    outline:none;
  }
  html{

  }
  body{

  }
  #root{
	flex-direction: column;
	min-height: 100vh;
  }
  input::placeholder{
    color: ${props => props.theme.placeholder};
  }
`
