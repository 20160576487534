import React from 'react'
import styled from 'styled-components'
import { Brazil, Italy, UK, USA } from '../../assets/Images'
import { QuadroProps } from './Types'

export default function Quadro({click,id,nome,url,pais}:QuadroProps) {
	return (
		<Container onClick={() => click(id)}>
			<Imagem src={url} alt={`foto de ${nome}`}></Imagem>
			<Label>
				<Nome>{nome}</Nome>
				<Bandeira>
					{pais === "Brasil" && <Brazil/>}
					{pais === "EUA" && <USA/>}
					{pais === "Itália" && <Italy/>}
					{pais === "Reino Unido" && <UK/>}
				</Bandeira>
			</Label>
		</Container>
	)
}
const Container = styled.div`
	width:auto;
	padding:30px;
	cursor:pointer;
`

const Label = styled.div`
	margin-top: -5px;
	border-left:5px solid ${props => props.theme.orange};
	height:60px;
	padding-left:10px;
	padding-top:10px;
`

const Imagem = styled.img`
	box-shadow: -20px -15px ${props => props.theme.orange};
	width:290px;
	height:auto;
`

const Nome = styled.div`
	color:${props => props.theme.lightBlue};
	font-size:20px;
`

const Bandeira = styled.div`
	margin-top:3px;
	& img{
		height:20px;
		width:auto;
	}
`