import React from 'react'
import styled from 'styled-components'
import { Dasa, Geneone2 } from '../../assets/Images'

export default function Footer() {
	return (
		<Linha1>
			<ImagemDasa>
				<Dasa />
			</ImagemDasa>
			<ImagemGeneOne >
				<Geneone2 />
			</ImagemGeneOne>
		</Linha1>
	)
}

const Linha1 = styled.div`
	background-color:${props => props.theme.darkBlue};
	margin-top:0px;
	padding:10px;
	width:100%;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-around;
	box-shadow: 0px 1px 50px 46px #ffffff17;	

`

const ImagemDasa = styled.div`
	& svg{
		height:25px;
	}
	@media (max-width: 950px) {
		& svg{
			height:15px;
		}
	}
`
const ImagemGeneOne = styled.div`
	@media (max-width: 950px) {
		& svg{
			height:50px;
		}
	}
`

