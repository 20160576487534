import React from 'react'
import { AgendaTileProps } from './Types'
import styled from 'styled-components'
import { Button, ButtonFlat } from '../button/Button'
import { Brazil, Italy, UK, USA } from '../../assets/Images'

export default function AgendaTile({ assistir, horario, nome, saibaMais, imagem, titulo, pais, url_video_1, ao_vivo }: AgendaTileProps) {
	return (
		<Container>
			<ContainerEsquerda>
				<Hora>{horario}</Hora>
				<Nome>{nome}</Nome>
				<Bandeira>
					{pais === "Brasil" && <Brazil />}
					{pais === "EUA" && <USA />}
					{pais === "Itália" && <Italy />}
					{pais === "Reino Unido" && <UK />}
				</Bandeira>
			</ContainerEsquerda>
			{imagem && <Imagem src={imagem} />}
			<Titulo>
				{titulo}
			</Titulo>
			<ButtonContainer>
				<ButtonFlat onClick={saibaMais}>Saiba mais</ButtonFlat>
				{ao_vivo === "0" && url_video_1 && <Button onClick={assistir}>Assistir</Button>}
				{ao_vivo === "1" && <Button onClick={assistir}>Ao Vivo</Button>}
			</ButtonContainer>
		</Container>
	)
}

const Container = styled.div`
	padding:10px;
	height:140px;
	z-index:2;
	background-color:${props => props.theme.darkBlue3};
	display:flex;
	flex-direction:row;
	box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.25);
	min-width:832px;

	margin-bottom:30px;

	@media (max-width: 950px) {
		flex-direction:column;
		height:unset;
		min-width:unset;
	}
	
`
const ContainerEsquerda = styled.div`
	padding-right:20px;
	display:flex;
	width:230px;
	min-width:230px;
	flex-direction:column;
	justify-content:center;
	align-items:flex-end;
	@media (max-width: 950px) {
		width:100%;
		min-width:150px;
		align-items:center;
	}
`

const ButtonContainer = styled.div`
	margin-left:auto;
	padding-left:20px;
	width:230px;
	min-width:230px;
	display:flex;
	flex-direction:row;
	justify-content:end;
	align-items:center;
	& div{
		margin-left: 10px;
	}
	
	@media (max-width: 950px) {
		margin: 20px 0;
		padding-left:unset;
		width: 100%;
		justify-content:center;
	}
`

const Hora = styled.div`
	font-size:32px;
	font-weight:700;
	color:${props => props.theme.orange};
	/* @media (max-width: 950px) {
		font-size:12px;
	} */
`
const Nome = styled.div`
	font-size:20px;
	color:${props => props.theme.lightBlue};
	/* @media (max-width: 950px) {
		font-size:12px;
	} */
`
const Imagem = styled.img`
	height:100px;
	width:auto;
	margin:9px;
	box-shadow: -9px 9px ${props => props.theme.orange};
	@media (max-width: 950px) {
		margin: 0 auto 20px;
		height: auto;
		width: 90px;
	}
`
const Titulo = styled.div`
	color:white;
	font-size:24px;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:flex-end;
	user-select: none;
	margin-left: 10px;
	line-height: 140%;
	@media (max-width: 950px) {
		align-items: center;
		text-align: center;
		margin: 0 15px;
	}
`

const Bandeira = styled.div`
	& img{
		margin-top: 5px;
		height: 20px;
		width: auto;
	}
	@media (max-width: 950px) {
		& img{
			margin-bottom: -116px;
			margin-left: 140px;
		}
	}
	
`

