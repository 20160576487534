import React from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components'
import Carousel from '../../components/Carousel/Carousel'
import Quadro from '../../customComponents/quadro/Quadro'
import store from '../../store';
import { getSpeakerState } from '../../store/slices/speaker';

function Speakers({speaker,click}:any) {
	return (
		<Container>
			<Flag id="speaker" />
			<Titulo>
				Speakers 2021
			</Titulo>
			<CarrouselContainer>
				<Carousel>
					{speaker.dados.map((dado:any,index:number) => <Quadro click={click} key={index} id={dado.Id} nome={dado.Nome} url={dado.Imagem} pais={dado.Pais}></Quadro>)}
				</Carousel>
			</CarrouselContainer>
		</Container>
	)
}

export default connect(state => ({ speaker: getSpeakerState(store.getState()) }))(Speakers)

const Flag = styled.div`
	position:absolute;
	top:-210px;
`

const Container = styled.div`
	position:relative;
	margin-top:100px;
	overflow-x:hidden;
`

const Titulo = styled.div`
	margin-bottom:100px;
	color:${props => props.theme.lightBlue};
	font-size:50px;
	font-weight:700;
	text-align:center;
	user-select: none;
	@media (max-width: 950px) {
		font-size:35px;
		margin-bottom:50px;
	}
`

const CarrouselContainer = styled.div`

`