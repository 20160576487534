import { createGlobalStyle } from 'styled-components'

import DasaBoldEot from './DasaSans-Bold.eot'
import DasaBoldWoff2 from './DasaSans-Bold.woff2'
import DasaBoldWoff from './DasaSans-Bold.woff'
import DasaBoldTTF from './DasaSans-Bold.ttf'


import DasaMediumEot from './DasaSans-Medium.eot'
import DasaMediumWoff2 from './DasaSans-Medium.woff2'
import DasaMediumWoff from './DasaSans-Medium.woff'
import DasaMediumTTF from './DasaSans-Medium.ttf'


import DasaRegularEot from './DasaSans-Regular.eot'
import DasaRegularWoff2 from './DasaSans-Regular.woff2'
import DasaRegularWoff from './DasaSans-Regular.woff'
import DasaRegularTTF from './DasaSans-Regular.ttf'



export default createGlobalStyle`

	@font-face {
	font-weight: 300;
	font-family: 'DasaSans';
	font-style: normal;
	src: url(${DasaRegularEot}); /* IE9 Compat Modes */
	src: url(${DasaRegularWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${DasaRegularWoff}) format('woff'), /* Modern Browsers */
		url(${DasaRegularTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 400;
	font-family: 'DasaSans';
	font-style: normal;
	src: url(${DasaRegularEot}); /* IE9 Compat Modes */
	src: url(${DasaRegularWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${DasaRegularWoff}) format('woff'), /* Modern Browsers */
		url(${DasaRegularTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 500;
	font-family: 'DasaSans';
	font-style: normal;
	src: url(${DasaMediumEot}); /* IE9 Compat Modes */
	src: url(${DasaMediumWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${DasaMediumWoff}) format('woff'), /* Modern Browsers */
		url(${DasaMediumTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 600;
	font-family: 'DasaSans';
	font-style: normal;
	src: url(${DasaBoldEot}); /* IE9 Compat Modes */
	src: url(${DasaBoldWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${DasaBoldWoff}) format('woff'), /* Modern Browsers */
		url(${DasaBoldTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 700;
	font-family: 'DasaSans';
	font-style: normal;
	src: url(${DasaBoldEot}); /* IE9 Compat Modes */
	src: url(${DasaBoldWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${DasaBoldWoff}) format('woff'), /* Modern Browsers */
		url(${DasaBoldTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 800;
	font-family: 'DasaSans';
	font-style: normal;
	src: url(${DasaBoldEot}); /* IE9 Compat Modes */
	src: url(${DasaBoldWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${DasaBoldWoff}) format('woff'), /* Modern Browsers */
		url(${DasaBoldTTF}) format('truetype'); /* Safari, Android, iOS */
	}

`