import axios from 'axios';
import store from '../store/'
import {apiUrl, chatGUID, jsonURL} from '../config'
import agendaState from '../store/slices/agenda';
import speakerState from '../store/slices/speaker';
import loginState, { getLoginState } from '../store/slices/login';
import messageState from '../store/slices/messages';
import videoState from '../store/slices/video';

export const getSpeakers = async () => {
	const a = await axios.get(`${jsonURL}/json/plenaria_speakers.json?${(new Date()).getTime()}`)
	if(a.status !== 200) console.error("não foi possivel carregar o speaker.json")
	if(a.status === 200) store.dispatch(speakerState.actions.changeSpeaker(a.data));
}

export const getAgenda = async () => {
	const a = await axios.get(`${jsonURL}/json/programacao.json?${(new Date()).getTime()}`)
	if(a.status !== 200) console.error("não foi possivel carregar a programacao.json")
	if(a.status === 200) store.dispatch(agendaState.actions.changeAgenda(a.data));
}

export const getStreaming = async (version:any = null,tentativa:any = 0) => {
	const a = await axios.get(`${jsonURL}/json/streaming.json?${(new Date()).getTime()}`)
	if(a.status !== 200) console.error("não foi possivel carregar a programacao.json")
	if(a.status === 200) {
		if(version && a.data.version < version && tentativa < 3){
			setTimeout(() => {
				getStreaming(version,tentativa+1)
			},5000)
		}
		if(a.data.data[0].urls && Array.isArray(a.data.data[0].urls)){
			store.dispatch(videoState.actions.changeAoVivo({ao_vivo:true}));	
		}else{
			store.dispatch(videoState.actions.changeAoVivo({ao_vivo:false}));	
		}
		store.dispatch(videoState.actions.changeVideo({urls:a.data.data[0].urls || []}));
	}
}

export const login = async (email:string) => {
	const res = await axios.post(`${apiUrl}/custom/api/login/email_auto.php`,{
		"email": email,
		"keep_connected": 1
	},{withCredentials: true});
	return res.status === 200? res.data:null;
}

export const sendMessage = async (message: any) => {
	const login = getLoginState(store.getState());
	const res = await axios.post(`${apiUrl}/custom/api/chat/msg_add.php`, {
		"message": message,
		"chat_guid": chatGUID,
		"token": login.token,
		"guid": login.guid,
	}, { withCredentials: true });
	if (res.status === 200 && res.data.status === -5) { store.dispatch(loginState.actions.logout());}
	return res;
}

export const loadPrevMessages = async () => {
	const login = getLoginState(store.getState());
	const res = await axios.post(`${apiUrl}/custom/api/chat/history.php?`, {
		"chat_guid": chatGUID,
		"token": login.token,
		"guid": login.guid,
	}, { withCredentials: true });
	if (res.status === 200 && res.data.status === 1) {
		store.dispatch(messageState.actions.prevMessages(res.data.data));
	}
	if (res.status === 200 && res.data.status === -3) { store.dispatch(loginState.actions.logout());}
}

export const loadVideoAoVivo = async() => {
	const login = getLoginState(store.getState());
	const res = await axios.post(`${apiUrl}/custom/api/plenary/list.php?`,{
		"token": login.token,
		"guid": login.guid,
	} ,{ withCredentials: true });
	if (res.status === 200 && res.data.status === 1) {
		if(res.data.data.length > 0){
			store.dispatch(videoState.actions.changeVideo({
				src:res.data.data
			}));
		}	
	}
	if (res.status === 200 && res.data.status === -3) { store.dispatch(loginState.actions.logout());}
	if (res.status === 200 && res.data.status === -2) { store.dispatch(loginState.actions.logout());}
	if (res.status === 200 && res.data.status === -1) { store.dispatch(loginState.actions.logout());}
}

export const checkChatStatus = async () => {
	const login = getLoginState(store.getState());
	const res = await axios.post(`${apiUrl}/custom/api/chat/status.php?`,{
		"token": login.token,
		"guid": login.guid,
	} ,{ withCredentials: true });
	if (res.status === 200 && res.data.status === 1) {
		store.dispatch(videoState.actions.changeChat({chat:!!parseInt(res.data.data.status,10)}));
	}
	if (res.status === 200 && res.data.status === -1) { store.dispatch(loginState.actions.logout());}
}

export const checkAoVivo = async () => {
	const res = await axios.get(`${apiUrl}/custom/api/plenary/aovivo_status.php?`);
	if (res.status === 200 && res.data.status === 1) {
		store.dispatch(videoState.actions.changeAoVivo({ao_vivo:res.data.data.status}));
	}
}

