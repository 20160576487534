import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp,faFacebookF,faInstagram,faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export default function Contato() {
	return (
		<Container id="contato">
			<Titulo>
				Contatos
			</Titulo>
			<Info>
				<Col1>
					<Email><FontAwesomeIcon style={{color:'#FF4E33'}} icon={faEnvelope} />genomica@geneone.com.br</Email>
					<Phone><FontAwesomeIcon style={{color:'#FF4E33'}} icon={faPhoneAlt} />(011) 4003-5660</Phone>
					<Whats><FontAwesomeIcon style={{color:'#FF4E33'}} icon={faWhatsapp} />(011) 99561-8428</Whats>
				</Col1>
				<Col2>
					<Facebook><FontAwesomeIcon style={{color:'#FF4E33'}} icon={faFacebookF} />labgeneone</Facebook>
					<Insta><FontAwesomeIcon style={{color:'#FF4E33'}} icon={faInstagram} />@geneonebrasil</Insta>
					<Linke><FontAwesomeIcon style={{color:'#FF4E33'}} icon={faLinkedinIn} />geneone</Linke>
				</Col2>
			</Info>
		</Container>
	)
}

const Container = styled.div`
	margin-top:-400px;
	min-height:800px;

	background-image:url('/assets/fundo_contato.jpg');
	background-size:contain;
	background-position-x:right;
	background-position-y:bottom;
	background-repeat:no-repeat;
`

const Titulo = styled.div`
	padding-top:500px;
	padding-bottom:50px;
	color:${props => props.theme.lightBlue};
	font-size:50px;
	font-weight:700;
	text-align:center;
	user-select: none;
	@media (max-width: 950px) {
		font-size:35px;
	}

`

const Info = styled.div`
	color:white;
	display:flex;
	flex-direction:row;
	justify-content:center;
	font-size:20px;
	line-height:32px;

	@media (max-width: 950px) {
		font-size:15px;
		flex-direction:column;
		justify-content:center;
		align-items:left;
		padding-left:60px;
		padding-bottom:30px;
	}
`

const Col1 = styled.div`
	display:flex;
	flex-direction:column;
	margin-right: 50px;
	
	@media (max-width: 950px) {
		margin-right: unset;
	}
	& div{
		margin-bottom: 10px;
	}
`
const Col2 = styled.div`
	display:flex;
	flex-direction:column;

	& div{
		margin-bottom: 10px;
	}
`

const Email = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	& svg{
		font-size:26px;
		margin-right: 10px;
	}

`

const Phone = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	& svg{
		font-size:26px;
		margin-right: 10px;
	}
`

const Whats = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	& svg{
		font-size:26px;
		margin-right: 10px;
	}
`

const Facebook = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	& svg{
		font-size:26px;
		margin-right: 10px;
	}
`

const Linke = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	& svg{
		font-size:26px;
		margin-right: 10px;
	}
`

const Insta = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	& svg{
		font-size:26px;
		margin-right: 10px;
	}
`