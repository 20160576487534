import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../customComponents/button/Button'
import Footer from '../../customComponents/footer/Footer'
import Navbar from '../../customComponents/navbar/Navbar'
import { login } from '../../services/apiCalls'
import loginState from '../../store/slices/login'
import { getRedirectToState } from '../../store/slices/redirectTo'

export default function Login() {
	const dispatch = useDispatch()
	const history = useHistory();
	const [email, setEmail] = useState('')
	const [titulo, setTitulo] = useState('Preencha seu e-mail!')
	const [texto, setTexto] = useState('')
	const redirectTo = useSelector(getRedirectToState);

	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			doLogin();
		}
	}

	const doLogin = async() => {
		const res = await login(email);
		if(!res){
			setTitulo("E-mail não encontrado!")
			setTexto("Favor entrar em contato com [11] 99561-8428")
		}
		if(res){
			if(res.status ===1){
				const {register_data,token,name,guid,email} = res.data;
				dispatch(loginState.actions.login({token,realizouCadastro:register_data,nome:name,email,guid}));
				//history.push(redirectTo.redirect);
				history.push('/chat');
			}else{
				setTitulo("E-mail não encontrado!")
				setTexto("Favor entrar em contato com [11] 99561-8428")
			}
		}
	}

	return (
		<Container>
			<Navbar />
			<ContentContainer>
				<LoginContainer>
					<LoginBox>
						<Input onKeyDown={enterAction} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail"></Input>
						<Button onClick={doLogin} style={{ width: '100%', height: 64, fontSize: 22, textTransform: 'capitalize' }}>Login</Button>
						<div>
							<Titulo>{titulo}</Titulo>
							<Text>{texto}</Text>
						</div>
					</LoginBox>
				</LoginContainer>
				<Footer />
			</ContentContainer>
		</Container>
	)
}

const LoginContainer = styled.div`
	padding:20px;
	height:100%;
	width:100%;
	display:flex;
	justify-content:center;
`

const Container = styled.div`
	background-color:#00194f;
	width:100%;
	min-width:100%;
`
const ContentContainer = styled.div`
	background-image:url('/assets/login_bg.jpg');
	background-size:cover;
	background-position-x:right;
	background-position-y:bottom;
	min-width:100%;
	min-height: 100%;
	height: calc(100vh - 200px);
	flex-direction:column;
	display:flex;
	justify-content:center;
	
	
	@media (max-width: 950px) {
		margin-top: 60px;
		height: calc(100vh - 110px);
	}
`

const LoginBox = styled.div`
	align-self:center;
	width:500px;
	height:350px;
	background-color: ${props => props.theme.darkBlue};
	box-shadow: 0px 40px 100px rgba(108, 203, 210, 0.25), 0px -4px 200px rgba(108, 203, 210, 0.25);
	display:flex;
	flex-direction:column;
	padding:50px;
	align-items:center;
	gap:20px;

`

const Input = styled.input`
	border: 1px solid #A9E1FE;
	background-color:${props => props.theme.darkBlue};
	width: 100%;
	height: 64px;
	font-size:22px;
	padding:10px;
	color:white;	
	::placeholder{
		color:white;	
	}
`

const Titulo = styled.div`
	font-size: 22px;
	line-height: 150%;
	text-align: center;
	color: #A9E1FE;
`

const Text = styled.div`
	font-size: 18px;
	line-height: 150%;
	text-align: center;
	color: white;
`
