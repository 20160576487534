import React from 'react'
import { Img16x9 } from '../../assets/Images';


function youTubeGetId(url:any){
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length===11)? match[7] : false;

}
export default function Video({src}:any) {
	
	if(!src) return (<Img16x9 />)
	if(
		src.toLocaleLowerCase().indexOf(".png") > 0 || 
		src.toLocaleLowerCase().indexOf(".gif") > 0 ||
		src.toLocaleLowerCase().indexOf(".jpg") > 0 ||
		src.toLocaleLowerCase().indexOf(".jpeg") > 0
	){
		return(<img src={src} alt="imagem preview"></img>);
	}
	if(src.indexOf(".mp4") > 0)
		return (<video controls autoPlay> <source src={src} type="video/mp4" /><p>Seu navegador não suporta a execução de vídeos.</p></video>)
	
	if(src.indexOf("youtu") > 0){
		const videoId = youTubeGetId(src);
		if(videoId){
			return (
			<>
				<Img16x9 />
				<iframe title="video" src={`https://www.youtube.com/embed/${videoId}?autoplay=1`} frameBorder={0} allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'} allowFullScreen></iframe>);
			</>)
		}else{
			return (<div/>)
		}
	}

	/* Vimeo */
	if(src.indexOf("vimeo") > 0){

		let vimeo_url = '';
		if(src.indexOf("player.vimeo") > 0){
			vimeo_url = src;
			} else {
			var vimeo = src.replace('https://vimeo.com/','');
			var vimeo_ar = vimeo.split('/');
			var vimeo_id = vimeo_ar[0];		
			vimeo_url = 'https://player.vimeo.com/video/'+vimeo_id;
		}

		return(
		<>
			<Img16x9 />
			<iframe title="video" src={`${vimeo_url}?autoplay=1`} frameBorder={0} allow={'autoplay; fullscreen'} allowFullScreen></iframe>
		</>
		);
	}
	return (<div/>)
}
