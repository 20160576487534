import styled from "styled-components";

export const LogoContainer = styled.div`
	display:flex;
	width:100%;
	padding-left:5%;
	padding-right:5%;
	
	justify-content:space-between;
	align-items: baseline;
	margin-top: 30px;
	margin-bottom: 40px;
	& .encontro{
		height:60%;  
	} 

	& .logo_tim{
		height:20%;  
	}

	@media (max-width: 768px){ 
		display:none;
	}
`
export const AudiosMobile = styled.div`
	
	display:none;
	flex-direction:row;
	gap:10px;
	@media (max-width: 1200px){ 
		display:flex;
	}
`

export const Audios = styled.div`
	padding-left:20px;
	display:flex;
	flex-direction:row;
	gap:10px;
	margin-bottom:30px;
	@media (max-width: 1200px){ 
		display:none;
	}
`
export const MainContainer = styled.div`
	height:100%;
	padding:20px;
	display: flex;
	flex-direction: row;
	width: 100%;
	gap:10px;
	@media (min-width: 1200px){ 
		padding-bottom:5px;
	}
	

  @media (max-width: 1200px){ 
	max-width:100%;
	flex-direction: column;
	gap:15px;
  }
`
export const ChatContainer = styled.div`
	background-color:red;
	flex-grow:1;
	display:flex;
	flex-direction:column;
	@media (max-width: 1200px){ 
		min-height:640px;
  	}
`
export const MessageContainer = styled.div`
	background-color:white;
	flex-grow:1;
	overflow-y: scroll;
	border-radius:2px;
	background-color:white;
`

export const Label = styled.div`
  color:${props => props.theme.AccentBlue};
  padding-bottom:10px;
  font-size:18;
  font-weight:500;
`

export const VideoContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1150px;
`

export const Chat = styled.div`
  width: 700px;
  padding:12px;
  min-height:100%;
  background-color:${props => props.theme.orange};
  color:white;
  font-size:20px;
  font-weight:bold;
  display:flex;
  flex-direction:column;
  
  @media (max-width: 1200px){ 
	width:100%;
	max-height:700px;
	min-height:700px;
  }
`

export const EncontroMobile = styled.div`
display:none;

 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:50px;
	margin-bottom:50px;
	& img{
		width:70%;
	} 
 } 
`
export const LogoMobile = styled.div`
display:none;

 @media (max-width: 768px){ 
	display:flex;
	justify-content:center;
	height:auto;
	margin-top:10px;
	margin-bottom:80px;
	& img{
		width:70%;
	} 
 } 
`


export const IFrameContiner = styled.div`
	position:relative;
	z-index: 2;
	border-radius:6px;
	& img {display:block;width:100%;height:auto;}
	& iframe{
		position:absolute;
		top:0;
		left:0;
		width:100%; 
		height:100%;
	}
`
