import styled from 'styled-components'

export const Button = styled.div`
	cursor: pointer;
	border: 2px solid ${props => props.theme.orange};
	background-color:${props => props.theme.orange};
	height:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	&:hover{
		border: 2px solid ${props => props.theme.orange};
		color:${props => props.theme.orange};
		background-color:white;
	}
`
export const ButtonDisabled = styled.div`
	user-select: none;
	border: 2px solid #999999;
	background-color:#999999;
	height:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

`

export const ButtonFlat = styled.div`
	cursor: pointer;
	border: 2px solid ${props => props.theme.orange};
	height:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	&:hover{
		border-color:white;
		background-color:${props => props.theme.orange};
	}
`

export const ButtonFlatBlue = styled.div`
	cursor: pointer;
	border: 2px solid white;
	height:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	&:hover{
		font-weight:bold;
		border-color:white;
		color:${props => props.theme.darkBlue};
		background-color:white;
	}
`