import React from 'react'
import styled from "styled-components";

export default function Message({nome,hora,message}:any) {

	const allnames = nome.split(' ');
	const shortName = allnames.length > 1 ? `${allnames[0][0]}${allnames[allnames.length - 1][0]}` : allnames[0].slice(0,2);

	return (
		<Container>
			<BallContainer>
				<Ball>{shortName}</Ball>
			</BallContainer>
			<LineContainer style={{}}>
				<Line1 >
					<Name>{nome}</Name>
					<Time>{hora}</Time>
				</Line1>
				<Line2 >
					<Msg>
						{message}
					</Msg>
				</Line2>
			</LineContainer>
		</Container>
	)
}

const BallContainer = styled.div`
	display:flex;
	align-items:center;
`

const Ball = styled.div`
	background-color:${props => props.theme.darkBlue};
	min-height:40px;
	min-width:40px;
	max-height:40px;
	max-width:40px;
	display:flex;
	justify-content:center;
	align-items:center;
	color:white;
	text-transform:uppercase;
	margin:10px;
`

const Name = styled.div`
	font-size:12px;
	color:${props => props.theme.orange};
`
const Time = styled.div`
	font-size:10px;
	color:${props => props.theme.orange};
`

const Msg = styled.div`
	color:${props => props.theme.darkBlue};
	font-size:16px;
	line-height:20px;
	padding-bottom:5px;
`

const Line1 = styled.div`
	padding-bottom:5px;
	display:flex;
	width:100%;
	justify-content:space-between;
`

const Line2 = styled.div`

`
const LineContainer = styled.div`
	width:100%;
	margin:10px;
	border-bottom: 1px solid ${props => props.theme.DarkBlueTim};
`

const Container = styled.div`
	display:flex;
	width:100%;
	flex-grow:1;
	font-weight:400;
`



