import React, { useState } from 'react'
import styled from "styled-components";
import { emojisList } from '../../assets/emojis';

export default function SendMessage({ click,enableChat }: any) {
	const [inputValue, setInputValue] = useState("");
	const [chooseEmote, setChooseEmote] = useState(false);

	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			clickAction();
		}
	}

	const clickAction = () => { 
		if(!enableChat) return;
		if (inputValue === "") return;
		if (click) click(inputValue);
		setInputValue("");
	};
	return (
		<Container>
			<Background>
				<EmoteContainer><Emote onClick={() => setChooseEmote(!chooseEmote)}>🤗</Emote>
					{
						chooseEmote && <ChooseEmote>
							{emojisList.map((emoji,index) => (<div key={index} onClick={() => {setChooseEmote(false);setInputValue(inputValue + emoji)}}>{emoji}</div>))}
						</ChooseEmote>
					}

				</EmoteContainer>
				<Input disabled={!enableChat} spellCheck="true" onKeyDown={enterAction} value={enableChat?inputValue:''} onChange={(e) => setInputValue(e.target.value)} placeholder={enableChat?'Digite algo aqui...':''}></Input>
				<SendContainer enableChat={enableChat} onClick={clickAction} >
					
				</SendContainer>
			</Background>
		</Container>
	)
}

const Container = styled.div`
	background-color:white;
	/* padding-top:20px;
	padding-bottom:20px; */
	padding:20px;
`;

const Background = styled.div`
	background-color:white;
	display:flex;
	background-color:#F2F2F2;
	border-top-left-radius:30px;
	border-bottom-left-radius:30px;
	height:52px;
	border:1px solid ${props => props.theme.DarkBlueTim};
`;

const EmoteContainer = styled.div`
	position:relative;
	display:flex;
	align-items:center;
`
const Emote = styled.div`
	font-size:34px;
	border-radius:50%;
	min-height:40px;
	min-width:40px;
	max-height:40px;
	max-width:40px;
	display:flex;
	justify-content:center;
	align-items:center;
	color:white;
	text-transform:uppercase;
	margin:10px;
	cursor: pointer;
`
const ChooseEmote = styled.div`
	position:absolute;
	display:flex;
	flex-wrap:wrap;
	overflow-y:scroll;
	top: -200px;
	width:400px;
	height:200px;
	background-color:white;
	user-select: none;

	& div{
		min-height:30px;
		min-width:30px;
		max-height:30px;
		max-width:30px;
		font-size:20px;
		cursor: pointer;
		user-select: none;
	}
`


const Input = styled.input`
	height:50px;
	border:0px;
	width:100%;
	background-color:inherit;
	padding-left:5px;
	font-size:14px;
`

const SendContainer = styled.div<{enableChat: boolean}>`
	min-height:50px;
	min-width:40px;
	max-height:50px;
	max-width:40px;
	background-image:url('/assets/seta.jpg');
	background-size:cover;
	background-color:inherit;
	border:none;
	cursor: ${props => props.enableChat? 'pointer':'no-drop'};
`
