import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import Socket from './components/Socket/Socket';
import RouterComponent from './router/router';
import start from './services/start';
import store from './store'

function App() {
	useEffect(() => {
		start();
	}, [])

	return (
		<>
			<Provider store={store}>
				<Socket/>
				<RouterComponent />
			</Provider>
		</>
	);
}

export default App;
