import React, { CSSProperties } from 'react'
import {ReactComponent as DasaLogo} from './Dasa.svg'
import {ReactComponent as GeneoneLogo} from './Geneone.svg'
import {ReactComponent as GeneoneLogo2} from './Geneone2.svg'
import {ReactComponent as RolarSVG} from './Rolar.svg'
import {ReactComponent as GeneSVG} from './Gene.svg'
import {ReactComponent as MailSVG} from './mail.svg'
import {ReactComponent as LinkedinSVG} from './linkedin.svg'
import {ReactComponent as SetaSVG} from './Seta.svg'
import {ReactComponent as CloseSVG} from './Close.svg'
import {ReactComponent as BarsSolid} from './bars-solid.svg'

import GenoticLogo from './Genotic.png'
import BrazilPNG from './Flags/Brazil.png'
import ItalyPNG from './Flags/Italy.png'
import UKPNG from './Flags/UK.png'
import UsaPNG from './Flags/Usa.png'


// svg

export const MenuIcon = () => <BarsSolid />
export const Dasa = () => <DasaLogo />
export const Geneone = () => <GeneoneLogo />
export const Geneone2 = () => <GeneoneLogo2 />
export const Gene = () => <GeneSVG />
export const Rolar = () => <RolarSVG />
export const Mail = () => <MailSVG />
export const Linkedin = () => <LinkedinSVG />
export const Seta = () => <SetaSVG fill={'#FFFFFF'} stroke={'#FFFFFF'}/>
export const Close = () => <CloseSVG fill={'#FFFFFF'}/>


// images

export const Logo = () => <img style={{}} className="logo_genomic" src="/assets/genomic.jpg" alt="Evento Logo"/>
export const Img16x9 = ({style}:any) => <img style={{...style}} className="p_16x9" src="/assets/16x9.png" alt="placeholder video"/>
export const FundoEvento = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="seta" src="/assets/background_evento.jpg" alt="Fundo"/>
export const Genomic = () => <img  src={GenoticLogo} />

export const Brazil = () => <img  src={BrazilPNG} />
export const Italy = () => <img  src={ItalyPNG} />
export const UK = () => <img  src={UKPNG} />
export const USA = () => <img  src={UsaPNG} />

