import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { Rolar, Gene } from '../../assets/Images';


export default function SobreEvento() {
	const { t } = useTranslation();
	return (
		<ConstainerGeral id="sobre_evento">
			<Container>
				{/* <OrangeBar>
					<div>
						<Date>27/05</Date>
						<Titulo>{t('sobreEvento.titulo')}</Titulo>
					</div>
					<RolarTela>
						<Rolar />
						<RolarTelaText>{t('sobreEvento.rolar')}</RolarTelaText>
					</RolarTela>
				</OrangeBar> */}
			</Container>
			<ContainerDescritivo>
				<OrangeTextArea>
					<CenterText>
						<div>A GeneOne, laboratório de genômica da Dasa
						apresenta o Genomic Summit Masters 2021</div>
					</CenterText>
				</OrangeTextArea>
				<Gene />
				<EventText>
					<EventTitle>Um evento inédito, interativo e 100% virtual.</EventTitle>
					<div style={{maxWidth: 560}}>
						Dia 27 de Maio de 2021, quinta-feira, a partir das 17h, você tem um encontro
						marcado para expandir as fronteiras do conhecimento e disseminar
						o que há de mais moderno e avançado em precisão genômica.
					</div>
				</EventText>
			</ContainerDescritivo>
		</ConstainerGeral>
	)
}

const ConstainerGeral = styled.div`
	display:flex;
	flex-direction:column;
`

const Container = styled.div`
	background-image:url('/assets/fundo.jpg');
	background-size:cover;
	padding-top:51%;
	position:relative;
	box-shadow: 0px 0px 80px 30px #ffffff14;
`

const OrangeBar = styled.div`
	display:flex;
	flex-direction:column;
	justify-content:space-evenly;
	min-height:100%;
	padding-top:40px;
	top:0;
	left:10%;
	position:absolute;
	background-color:${props => props.theme.orange};
	width:18%;
	height:100%;
`

const Date = styled.div`
	padding-left:50px;
	font-size:40px;
	font-weight:700;
 	color: ${props => props.theme.darkBlue3};
`

const Titulo = styled.div`
	padding-left:50px;
	font-weight:700;
	color:white;
	width: max-content;
    white-space: pre-line;
	font-size:40px;
	user-select: none;
`

const RolarTela = styled.div`
	display:flex;
	flex-direction:column;
	align-items:center;
`
const RolarTelaText = styled.div`
	color:white;
`
const ContainerDescritivo = styled.div`
	display:flex;
	flex-direction:column;
	align-items:center;
	margin-top:40px;
	padding: 15px;
`
const OrangeTextArea = styled.div`
	background-color:${props => props.theme.orange};
	width:60%;
	height:90px;

	text-align:center;
	line-height:1.3;
	color:white;
	font-weight:700;
	margin-bottom: 40px;
	justify-content: center;

	@media (max-width: 950px) {
		font-size:13px;
		width:80%;
	}
`
const CenterText = styled.div`
	display:flex;
	height:100%;
	width:100%;
	max-width: 400px;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	padding: 0 15px;
	margin: 0 auto;

	@media (max-width: 950px) {
		font-size:13px;
	}

`
const EventText = styled.div`
	margin-top: 40px;
	color:white;
	text-align:center;
	line-height:1.5;
	@media (max-width: 950px) {
		font-size:13px;
	}
`
const EventTitle = styled.div`
	color:white;
	text-align:center;
	font-weight:700;
	@media (max-width: 950px) {
		font-size:13px;
	}
`