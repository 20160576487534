import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Modal from '../../components/Modal/Modal'
import { Background } from '../../customComponents/background/background'
import Descricao from '../../customComponents/descricao/Descricao'
import Footer from '../../customComponents/footer/Footer'
import Navbar from '../../customComponents/navbar/Navbar'
import Agenda from './Agenda'
import Contato from './Contato'
import SobreEvento from './SobreEvento'
import Speakers from './Speakers'

export default function Home() {
	const [modalOpen,setModalOpen] = useState(false);
	const [modalInfo,setModalInfo] = useState({});
	const { hash } = useLocation();
	
	const OpenModal = (info:any) => {
		setModalOpen(true);
		setModalInfo(info)
	}

	const CloseModal = () => {
		setModalOpen(false);
	}

	useEffect(() => {
		window.scrollTo(0,0);
	}, []);

	useEffect(() => {
	  if(document.getElementById(hash.slice(1)))
		  document.getElementById(hash.slice(1))?.scrollIntoView({behavior:'smooth'});
	  else
	  	window.scrollTo({top:0,behavior:'smooth'});
	}, [hash]);

	return (
		<Container id="home">
			<Modal open={modalOpen}>
				<Descricao id={modalInfo} closeModal={CloseModal} />
			</Modal>
			<Navbar />
			<Background>
				<Margin>
					<SobreEvento />
					<Speakers click={OpenModal} />
					<Agenda openModal={OpenModal} />
				</Margin>
				<Contato />
			</Background>
			<Footer />
		</Container>
	)
}

const Container = styled.div`
	background-color:#00194f;
	width:100%;
	min-width:100%;
`

const Margin = styled.div`
	height:auto;
	padding-right:10%;
	padding-left:10%;

	@media (max-width: 950px) {
		height:auto;
		padding-right:0%;
		padding-left:0%;
		margin-top: 50px;
	}
`