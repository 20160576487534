
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Message from '../../components/Message/Message';
import SendMessage from '../../components/Message/SendMessage';
import Video from '../../components/video/Video';
import { isProd } from '../../config';
import { checkChatStatus, getStreaming, loadPrevMessages, loadVideoAoVivo, sendMessage } from '../../services/apiCalls';
import { getColor } from '../../services/colors';
import { getMessageState } from '../../store/slices/messages';
import videoState, { getVideoState } from '../../store/slices/video';
import {  MainContainer, Label, Chat, VideoContent, IFrameContiner, MessageContainer, ChatContainer, Audios, AudiosMobile } from './styles';
import Footer from '../../customComponents/footer/Footer';
import Navbar from '../../customComponents/navbar/Navbar';
import { ButtonFlatBlue } from '../../customComponents/button/Button';
import { getAgendaState } from '../../store/slices/agenda';
import useWindowSize from '../../hooks/useWindowSize';
import { Try } from '../../components/Try/Try';




function ChatView() {
	useEffect(() => {
		loadPrevMessages();
		getStreaming();
		checkChatStatus();
	}, []);
	const enableChat = isProd? false:true;

	let refVideo = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLInputElement>;
	const [screenWidth] = useWindowSize()
	const chatRef = useRef(null);
	const video = useSelector(getVideoState);
	const messages = useSelector(getMessageState);
	const dispatch = useDispatch();
	let [urlReproducao,setUrlReproducao] = useState('');
	let [videoSize,setVideoSize] = useState(100);
	useEffect(() => {
		if(!Array.isArray(video.urls) || video.urls.length === 0 )
			setUrlReproducao(video.src);
		else{
			if(video.urls[video.videoAtual]){
				setUrlReproducao(video.urls[video.videoAtual].url)
			}else{
				setUrlReproducao(video.urls[0].url)
			}
		}
	}, [video])

	useEffect(() => {
		const objDiv = document.getElementById('chatContainer');
		if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
	});
	
	useEffect(() => {
		changeVideoSize();
	}, [screenWidth,refVideo])

	function changeVideoSize(){
		if(!refVideo.current) return;
		setVideoSize(refVideo.current?.getBoundingClientRect().height - 65);
		if(refVideo.current?.getBoundingClientRect().height - 65 < 0){
			setTimeout(changeVideoSize,100)
		}
	}
	
	function changeVideo(index:any){
		dispatch(videoState.actions.changeIdioma({videoAtual:index}));
	}
	



	return (
		<>
		<Container>
			<Navbar />
			<ContainerContainerContent>
				<MainContainer>
					<VideoContent>
						<IFrameContiner ref={refVideo}>
							<Video src={urlReproducao} />
						</IFrameContiner>
					</VideoContent>
					<AudiosMobile>
						{ video.urls.map((val:any,index:any) => <div key={index}><ButtonFlatBlue onClick={() => {changeVideo(index)}}>{val.label}</ButtonFlatBlue></div>) }
					</AudiosMobile>
					<Chat>
						<Label>Chat</Label>
						<ChatContainer style={{maxHeight:videoSize}}>
							<MessageContainer id={'chatContainer'} ref={chatRef}>
								{[...messages.messages].map((val: any,index:any) => (
									<Try key={index}><Message nome={val.name_author || 'Anonimo'} hora={val.date} message={val.message} color={getColor(val.guid_author?val.guid_author.toUpperCase():'')} /></Try>
								))}
							</MessageContainer>
							<SendMessage enableChat={video.chat} click={sendMessage} />
						</ChatContainer>
					</Chat>
				</MainContainer>
				<Audios>
					{ video.urls.map((val:any,index:any) => <div key={index}><ButtonFlatBlue onClick={() => {changeVideo(index)}}>{val.label}</ButtonFlatBlue></div>) }
				</Audios>
				<div style={{marginTop:'auto'}}>
					<Footer />
				</div>
			</ContainerContainerContent>
		</Container>
		</>
	);
}

const Container = styled.div`
	background-color:#00194f;
	width:100%;
	min-width:100%;
`
const ContainerContainerContent = styled.div`
	background-image:url('/assets/login_bg.jpg');
	background-size:cover;
	background-position-x:right;
	background-position-y:bottom;
	background-repeat: no-repeat;
	width:100%;
	min-height:calc(100vh - 200px);
	height:100%;
	display:flex;
	flex-direction:column;

	@media (max-width: 950px) {
		margin-top: 50px;
	}
`

export default ChatView;
