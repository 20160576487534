import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import AgendaTile from '../../customComponents/agendaTile/AgendaTile'
import store from '../../store'
import { getAgendaState } from '../../store/slices/agenda'

function Agenda({ agenda, openModal }: any) {
	const history = useHistory();
	return (
		<Container>
			<Flag id="agenda" />
			<Titulo>
				Agenda
			</Titulo>
			<AgendaContainer>
				<OrangeBar />
				{
					agenda.dados.map((dado: any, index: number) =>
					(
						<AgendaTile
							assistir={() => { history.push(`chat/${dado.id}`); }}
							horario={dado.horario_inicial}
							nome={dado.palestrantes[0]?.Nome}
							titulo={dado.titulo}
							saibaMais={() => openModal(dado.palestrantes[0].Id)}
							imagem={dado.palestrantes[0]?.Imagem}
							pais={dado.palestrantes[0]?.Pais}
							ao_vivo={dado.ao_vivo}
							url_video_1={dado.url_video_1}
							key={index}
						/>
					))
				}
			</AgendaContainer>
		</Container>
	)
}

export default connect(state => ({ agenda: getAgendaState(store.getState()) }))(Agenda)

const Flag = styled.div`
	position:absolute;
	top:-210px;
`

const Container = styled.div`
	position:relative;
	margin-top:100px;
	display:flex;
	flex-direction:column;
	justify-items:center;
	align-items:center;
	
`

const Titulo = styled.div`
	margin-bottom:100px;
	color:${props => props.theme.lightBlue};
	font-size:50px;
	font-weight:700;
	text-align:center;
	user-select: none;
`

const OrangeBar = styled.div`
	position:absolute;
	left:285px;
	background-color:${props => props.theme.orange};
	width:5px;
	height:100%;
	@media (max-width: 950px) {
    left: 50%;
    margin-left: -2px;
	}

`

const AgendaContainer = styled.div`
	position:relative;
	display:flex;
	flex-direction:column;
	width:100%;
	max-width:1100px;

	& div:last-child{
		margin-bottom:0px;
	}
`