
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Seta } from '../../assets/Images';
import { Try } from '../Try/Try';
import { CarouselProps } from './CarouselType'

export default function Carousel({ children }: CarouselProps) {
	let myRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLInputElement>;
	
	const nextScroll = () => {

		
		if(!myRef.current.children.item(0)) return;
		let firstChild = myRef.current.children.item(0) as HTMLInputElement;
		const itemGalleryWidth = firstChild.getClientRects()[0].width;
		const containerWidth = myRef.current.getClientRects()[0].width;
		const allSize = myRef.current.children.length * itemGalleryWidth;
		const maxMoveLeft = -(allSize - containerWidth);

		if(parseInt(myRef.current.style.left,10) - itemGalleryWidth <= maxMoveLeft)
			myRef.current.style.left = `${maxMoveLeft}px`;
		else
			myRef.current.style.left = `${parseInt(myRef.current.style.left,10) - itemGalleryWidth}px`;

	}

	const prevScroll = () => {
		if(!myRef.current.children.item(0)) return;
		let firstChild = myRef.current.children.item(0) as HTMLInputElement;
		const itemGalleryWidth = firstChild.getClientRects()[0].width;
		
		if(parseInt(myRef.current.style.left,10) + itemGalleryWidth >= 0)
			myRef.current.style.left = `0px`;
		else
			myRef.current.style.left = `${parseInt(myRef.current.style.left,10) + itemGalleryWidth}px`;
	}


	return (
		<Try>
			<div style={{position:'relative',overflowX:'hidden'}}>
				<Container style={{left:0}} ref={myRef} >
					{children}
				</Container>
				<SetaContainerLeft><QuadradoContainerLeft onClick={prevScroll}><Seta/></QuadradoContainerLeft></SetaContainerLeft>
				<SetaContainerRight><QuadradoContainerRight onClick={nextScroll}><Seta/></QuadradoContainerRight></SetaContainerRight>
			</div>
		</Try>
	)
}

const Container = styled.div`
	position:relative;
	display:flex;
	flex-direction:row;
	/* overflow-x:hidden; */
	scroll-behavior: smooth;
	margin-left:20px;
	margin-right:20px;
	transition: left .5s;
`

const SetaContainerRight = styled.div`
	position:absolute;
	display:flex;
	top:0px;
	right:0px;
	height:100%;
`

const SetaContainerLeft = styled.div`
	position:absolute;
	display:flex;
	top:0px;
	left:0px;
	height:100%;
`

const QuadradoContainerLeft = styled.div`
	position:absolute;
	right:-70px;
	width:70px;
	height:70px;
	align-self:center;
	cursor: pointer;
	& svg{
		transform: rotate(180deg);
		stroke: #FFFFFF33;
		width: 50px;
    	height: 50px;
	}
	@media (max-width: 950px) {
		right:-70px;
	}
`

const QuadradoContainerRight = styled.div`
	position:absolute;
	left:-50px;
	width:70px;
	height:70px;
	align-self:center;
	cursor: pointer;
	& svg{
		stroke: #FFFFFF33;
		width: 50px;
    	height: 50px;
	}
	@media (max-width: 950px) {
		left:-70px;
	}
`

